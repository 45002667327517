import { Outlet } from "react-router-dom";
import RouteLoadingIndicator from "../router/RouteLoadingIndicator";
import { useEffect } from "react";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { ProvideToasts } from "../services/toastService";
import { ProvideConfirmation } from "../components/ConfirmationDialog";
import useReload from "../hooks/useReload";

const App = () => {
  const reload = useReload();
  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void CookieConsent.run({
      disablePageInteraction: true,
      autoClearCookies: true,
      revision: 1,
      cookie: {
        sameSite: "Strict",
      },
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom right",
        },
        preferencesModal: {
          layout: "box",
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: true,
          readOnly: false,
        },
      },
      language: {
        default: "fr",
        translations: {
          fr: {
            consentModal: {
              title: "Cookies 🍪",
              description:
                "Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. En continuant votre navigation, vous acceptez l'utilisation de ces derniers.",
              revisionMessage:
                "<br><br> Notre politique de cookies a été mise à jour. Veuillez accepter nos cookies pour continuer à profiter de la meilleure expérience sur notre site.",

              acceptAllBtn: "J'accepte tous les cookies",
              showPreferencesBtn: "Paramétrer",
            },
            preferencesModal: {
              title: "Paramétrer les cookies 🍪",
              savePreferencesBtn: "Valider votre sélection",
              acceptAllBtn: "Tout accepter",
              acceptNecessaryBtn: "Tout refuser",
              sections: [
                {
                  title: "Politique de confidentialité & gestion des cookies",
                  // TODO: Add cookies policy
                  description:
                    '<a href="#" class="cc-link" target="_blank" rel="noreferrer">Politique de protection des données</a>.',
                },
                {
                  title: "Cookies Essentiels",
                  linkedCategory: "necessary",
                  description:
                    "Les cookies essentiels sont nécessaires au bon fonctionnement de notre site web et sont indispensables pour vous permettre d'utiliser ses fonctionnalités de base. Ces cookies ne collectent pas d'informations vous concernant qui pourraient être utilisées à des fins de marketing ou de suivi. Ils sont généralement définis en réponse à des actions que vous avez effectuées sur le site, telles que la connexion à un compte ou la sélection d'une langue de préférence. Vous pouvez configurer votre navigateur pour bloquer ou vous alerter sur ces cookies, mais certaines parties du site risquent de ne pas fonctionner correctement sans eux.",
                  cookieTable: {
                    caption: "Liste des cookies",
                    headers: {
                      name: "Nom",
                      domain: "Domaine",
                      expiration: "Expiration",
                      description: "Description",
                    },
                    body: [
                      {
                        name: "access_token",
                        domain: window.location.host,
                        expiration: "1 semaine",
                        description:
                          "Ce cookie est utilisé pour stocker les informations d'authentification d'un utilisateur connecté. Ce cookie est créé lorsque vous vous connectez à notre site web et permet à notre système de vous identifier en tant qu'utilisateur authentifié. Il est utilisé pour vous permettre d'accéder à des fonctionnalités réservées aux utilisateurs connectés, telles que l'accès à votre compte, la modification de vos préférences ou la gestion de vos commandes. Les données stockées dans ce cookie sont cryptées et ne sont pas accessibles à des tiers.",
                      },
                      {
                        name: "cc_cookie",
                        domain: window.location.host,
                        expiration: "1 mois",
                        description:
                          "Stocke les préférences de l'utilisateur concernant les cookies. Indique quels cookies sont acceptés ou refusés par l'utilisateur sur ce site.",
                      },
                      {
                        name: "__stripe_mid",
                        domain: `${window.location.host}, .stripe.com`,
                        expiration: "1 ans",
                        description:
                          "Identifiant unique de la transaction ou du compte Stripe lié à l'utilisateur (Merchant ID). Peut être persistant pour améliorer l'expérience ou le suivi des conversions.",
                      },
                      {
                        name: "__stripe_sid",
                        domain: `${window.location.host}, .stripe.com`,
                        expiration: "Session",
                        description:
                          "Identifiant de session Stripe. Utilisé pour maintenir la session utilisateur securement lors du processus de paiement ou autres interactions avec les éléments Stripe.",
                      },
                    ],
                  },
                },
                {
                  title: "Cookies Statistiques",
                  linkedCategory: "analytics",
                  description:
                    "Les cookies de statistiques nous permettent de collecter des informations sur la façon dont les visiteurs utilisent notre site web, afin d'améliorer son fonctionnement et de mieux répondre à vos besoins. Ces cookies nous aident à comprendre comment vous interagissez avec notre site, quelles pages vous trouvez les plus intéressantes et les plus utiles, et à identifier les éventuels problèmes techniques. Les données collectées sont anonymes et ne permettent pas de vous identifier personnellement.",
                  cookieTable: {
                    caption: "Liste des cookies",
                    headers: {
                      name: "Nom",
                      domain: "Domaine",
                      expiration: "Expiration",
                      description: "Description",
                    },
                    body: [
                      {
                        name: "_ga, _ga_*",
                        domain: window.location.host,
                        expiration: "2 ans",
                        description:
                          "Identifiant unique pour distinguer les utilisateurs. Utilisé pour suivre les sessions et les interactions sur le site. Permet de distinguer un utilisateur d’un autre.",
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return (
    <ProvideToasts>
      <ProvideConfirmation>
        <RouteLoadingIndicator />
        {/* NOTE: Place here in the Router all the Providers that need the router but need to be available everywhere */}
        <Outlet />
      </ProvideConfirmation>
    </ProvideToasts>
  );
};

export default App;
